<template>
    <div style="padding: 32px">
        <div class="flex justify-between items-center">
            <p class="font-bold text-lg">Project List</p>
        </div>
        <el-table :data="dataList" style="margin-top: 20px;">
            <el-table-column type="index" prop="index" label="Index" width="100px"></el-table-column>
            <el-table-column label="Project">
                <template slot-scope="scope">
                    {{ scope.row.project.project_ename ? scope.row.project.project_ename :
                        scope.row.project.project_name }}
                </template>
            </el-table-column>
            <el-table-column label="Actions">
                <template slot-scope="scope">
                    <el-button @click="view(scope.row)" type="primary" size="small">View Students</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import {
    getAccountApplicationsByContact
} from "../../api/eae";
import { getUserId } from "../../utils/store";
export default {
    data() {
        return {
            dataList: [],
        };
    },

    mounted() {
        this.fetchData();
    },
    methods: {

        view(row) {
            this.$router.push({
                path: '/home/projectStudents',
                query: {
                    account_id: row.account_id,
                    project_id: row.project._id.$id
                }
            })
        },

        fetchData() {
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            getAccountApplicationsByContact(getUserId()).then((res) => {
                this.dataList = res.data
            }).finally(() => loading.close());
        },

        goDetail(item) {

        },
    },
};
</script>

<style lang="scss">
.student-item {
    width: calc(50% - 10px);
    background-color: white;
    padding: 18px 24px;

    .student-avatar {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 18px;
    }

    .view-btn {
        padding: 6px 26px;
        border-radius: 5px;
        cursor: pointer;
        background: #579eff;
        color: white;
        font-size: 15px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }
}

.table-header {
    width: calc(100% / 6);
    word-wrap: break-word;
    padding: 12px;
    font-size: 14px;
    display: flex;
    align-items: center;

    &.active {
        color: #ff6450;
        font-weight: bold;
    }

    &.pending {
        color: #67c23a;
        font-weight: bold;
    }

    &.expired {
        color: #909399;
        font-weight: bold;
    }
}

.header-wrapper {
    background: rgba(#ff6450, 0.15);
}

.content-wrapper {
    background-color: white;
}

.entry-title {
    width: calc(100% / 6);
    word-wrap: break-word;
    padding: 12px;
    font-size: 14px;
    display: flex;
    align-items: center;
}
</style>